import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vueLazy from "vue-lazyload";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "amfe-flexible/index.js";
import "./assets/css/index.css";
import "./assets/css/font.css";
import "@/style/index.less";
import api from "../BASE_API";
const app = createApp(App);
app.use(store);
app.use(router);
app.use(vueLazy);
app.use(ElementPlus);
app.mount("#app");
app.config.globalProperties.baseApi = api.VUE_APP_BASE_API;
