import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/", // 概览首页
    name: "Index",
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/scheme", //解决方案
    name: "scheme",
    component: () => import("../views/scheme/index.vue"),
  },
  {
    path: "/schemeDetail", //关于我们
    name: "schemeDetail",
    component: () => import("../views/scheme/schemeDetails.vue"),
  },
  {
    path: "/hardware", //硬件产品
    name: "hardware",
    component: () => import("../views/hardWare/index.vue"),
  },
  {
    path: "/news", // 新闻资讯
    name: "News",

    component: () => import("../views/news/index.vue"),
  },
  {
    path: "/newsDetail", //关于我们
    name: "newsDetail",
    component: () => import("../views/news/newsDetails.vue"),
  },

  {
    path: "/aboutus", //关于我们
    name: "aboutus",
    component: () => import("../views/aboutUs/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
